import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-new';
import { builder, BuilderComponent, useIsPreviewing } from "@builder.io/react";
import Skeleton from 'react-loading-skeleton';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
import { useLocation } from 'react-router-dom';

const BlogPage = () => {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [content, setContent] = useState(null);
    const isPreviewingInBuilder = useIsPreviewing();
    const [seo, setSeo] = useState({
        title: '',
        description: '',
    });
    const [alternatives, setAlternatives] = useState({});
    const [showDonateButton, setShowDonateButton] = useState(true);

    useEffect(() => {
        async function fetchContent() {
            try {
                // Fetch Builder content
                const fetchedContent = await builder
                    .get(
                        // Replace 'blog' with your actual Builder model name if different
                        'blog',
                        {
                            url: window.location.pathname,
                            userAttributes: { locale: window.location.host.toLowerCase() },
                            options: { locale: window.location.host.toLowerCase() },
                        }
                    )
                    .promise();

                setContent(fetchedContent);

                // Extract SEO fields and alternatives from Builder.io content
                if (fetchedContent?.data) {
                    setSeo({
                        title: fetchedContent.data.title || 'Default Title',
                        description: fetchedContent.data.description || 'Default description.',
                    });
                    setAlternatives(fetchedContent.data.alternatives || {});
                    setShowDonateButton(fetchedContent.data.showDonateButton);
                    document.title = fetchedContent.data.title || 'Default Title';
                }
            } catch (error) {
                console.error("Error fetching Builder.io content:", error);
            }

            // After data is fetched, set loading to false
            setIsLoading(false);
        }

        fetchContent();
    }, [location.pathname]);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center min-h-screen bg-white">
                <Skeleton count={5} />
            </div>
        );
    }

    // Static fallback alt links
    const staticAlts = {
        'en-gb': `https://matwproject.org.uk${window.location.pathname}`,
        'x-default': `https://matwproject.org${window.location.pathname}`,
        'en-ca': `https://matwprojectca.org${window.location.pathname}`,
        'fr-fr': `https://matwproject.org/fr${window.location.pathname}`,
        'en-us': `https://matwprojectusa.org${window.location.pathname}`,
        'en-id': `https://matwprojectid.org${window.location.pathname}`,
        'en-my': `https://matwprojectmys.org${window.location.pathname}`,
        'en-sg': `https://matwprojectsgp.org${window.location.pathname}`,
    };

    // Merge static alts with any from Builder that share the same hreflang
    const mergedAlternatives = { ...staticAlts, ...alternatives };

    return (
        <NewHelmetLayout
            hideHighlights
            hideFooter
            hideDonateNowButton={!showDonateButton}
            className="bg-white"
            showRegularImg={false}
        >
            <Helmet>
                <title>
                    {seo.title || 'Muslim Charity | Islamic Charity | MATW Project'}
                </title>
                <link
                    rel="canonical"
                    href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`}
                />
                <meta
                    name="description"
                    content={
                        seo.description ||
                        'MATW Project - Muslim charity supporting Muslims around the world.'
                    }
                />
                {/* Alternate hreflang links */}
                {Object.entries(mergedAlternatives).map(([langKey, href]) => (
                    <link
                        rel="alternate"
                        href={href}
                        hreflang={langKey}
                        key={langKey}
                    />
                ))}
            </Helmet>

            {/** Only previewing + Builder scenario */}
            <BuilderComponent
                key={location.pathname}
                model="blog"
                content={content}
            />
        </NewHelmetLayout>
    );
};

export default BlogPage;
