import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaTimes } from "react-icons/fa";
import { closeSheet } from "../../../store/sheetToggler";
import { generateLinkWithLang } from "../../../utils/linkGenerator";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectSelectedCurrency } from "../../../store/user";
// import { setCartLength, setSelectWidgetProducts } from '../../../store/widgetCart';
import { ProductBoxCartSheet } from "../../../components/general/productBoxCartSheet";
import CustomButton from "../../../components/general/button";
import { Sheet } from "../../../components/ui/sheet";
import getSymbolFromCurrency from "currency-symbol-map";
import { IoReturnUpBackOutline } from "react-icons/io5";
import {
  BASKET_CACHE_KEY,
  HandleLocalStorage,
} from "../../../utils/handleLocalStorage";
import { OneClickCheckout } from "../../../components/steps/oneClickCheckout";
import AdminCosts from "../../../components/steps/components/adminCosts";
import useCart from "../../../hooks/cart/useCart";
import services from '../../../services';
import { ProductBoxCart } from './productBoxCart';
import useIsExactDomain from "../../../hooks/cart/useIsExactDomain";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { selectProducts } from "../../../store/products";

const stripePromise = loadStripe('pk_live_51O25i3EpTLP297fAAuxdDPYNX04EtyEqgVKa0Wxl7bdAG1elEa0Gr6kbOKsw1ntgCUX4bkSWKv7m5cF4GZ6POGM600TRvCpUVI');

export const CartSheet = ({ onAddQty, onReduceQty, onRemove }) => {
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const dispatch = useDispatch();
  const isUsa = useIsExactDomain('us.matwproject.org');
  const [selectedSubscribe, setSelectedSubscribe] = useState("one-off");
  const [userProductsCount, setUserProductsCount] = useState(0);
  const [topProducts, setTopProducts] = useState([0, 0, 0, 0]);
  const allProducts = useSelector((state) => selectProducts(state));

  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const getInitialBasket = () =>
    JSON.parse(localStorage.getItem(BASKET_CACHE_KEY)) || {};

  const [baskets, setBaskets] = useState(getInitialBasket());
  const { basketStates, setBasketStates, notifyStorageUpdate, handleCheckProduct, handleAddToGiving, handleRemoveProduct } = useCart();
  let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
  const [products, setProducts] = useState(baskets.products || []);
  const [total, setTotal] = useState(baskets.grandTotal || 0);

  const updateBasketState = () => {
    const updatedBasket = getInitialBasket();
    // console.log("updated baskete=", updatedBasket)
    setBaskets(updatedBasket);
    setProducts(updatedBasket.products || []);
    setTotal(updatedBasket.grandTotal || 0);
  };

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === BASKET_CACHE_KEY) {
        updateBasketState();
      }
    };
    const handleLocalStorageChange = (event) => {
      basketStatesFromLocalStorage = JSON.parse(
        localStorage.getItem(BASKET_CACHE_KEY),
      );
      setTotal(basketStatesFromLocalStorage.grandTotal || 0);
    };
    window.addEventListener("localStorageUpdated", handleLocalStorageChange);
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleAdminCost = value => {
    // Get fresh data from localStorage
    let tempBasket = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));

    if (tempBasket?.products?.length && tempBasket.products[0]?.product) {
      let tempGrandTotal = value;
      // let tempTotal = 0;

      tempBasket.products.map(item => {
        item.product ? (tempGrandTotal += item.total) : (tempGrandTotal = value);
        // item.product ? (tempTotal += item.total) : (tempTotal = 0);
        return null;
      });

      // Update the basket with new values
      tempBasket.adminCost = Number(value);
      tempBasket.grandTotal = Number(tempGrandTotal);
      // tempBasket.total = Number(tempTotal);
      tempBasket.recurring ? setSelectedSubscribe(tempBasket.recurring) : setSelectedSubscribe('one-off');

      if (selectedCurrencyStore && !tempBasket.currency) {
        tempBasket.currency = selectedCurrencyStore;
      }

      console.log('selectedSubscribe', selectedSubscribe);

      // setTimeout(() => {
        setBasketStates(tempBasket);
        HandleLocalStorage(tempBasket);
        setUserProductsCount(userProductsCount + 1);
        notifyStorageUpdate()
    }
};

  // useEffect(() => {
  //   const originalSetItem = localStorage.setItem;

  //   localStorage.setItem = (key, value) => {
  //     originalSetItem.apply(localStorage, [key, value]);
  //     if (key === BASKET_CACHE_KEY) {
  //       updateBasketState();
  //     }
  //   };

  //   return () => {
  //     localStorage.setItem = originalSetItem;
  //   };
  // }, []);
  // useEffect(() => {
  //   console.log("baskets updated==", basketStatesFromLocalStorage)
  //   if (basketStatesFromLocalStorage) {
  //     setProducts(basketStatesFromLocalStorage.products)
  //   }
  // }, [basketStatesFromLocalStorage]);

  useEffect(() => {
    // console.log("baskets updated==", basketStatesFromLocalStorage);
    if (
      basketStatesFromLocalStorage &&
      JSON.stringify(products) !==
      JSON.stringify(basketStatesFromLocalStorage.products)
    ) {
      setProducts(basketStatesFromLocalStorage.products);
    }
  }, [basketStatesFromLocalStorage, products]);

  // const groupedByCreator = products.reduce((acc, item) => {
  //   console.log('product',item)
  //     const creator = item.product?.creator || "Unknown";
  //     if (!acc[creator]) {
  //         acc[creator] = {
  //             ...item,
  //             creator,
  //             total: 0
  //         };
  //     }
  //     acc[creator].total += item.total;
  //     return acc;
  // }, {});

  // const groupedByCreator = useMemo(() => {
  //   return products.reduce((acc, item) => {
  //     console.log('product', item);
  //     const creator = item.product?.creator || "Unknown";
  //     if (!acc[creator]) {
  //       acc[creator] = {
  //         ...item,
  //         creator,
  //         total: 0
  //       };
  //     }
  //     acc[creator].total += item.total;
  //     return acc;
  //   }, {});
  // }, [products]); // ✅ Only recalculates when `products` changes
  // Group products by their "creator" while summing up quantities and totals
  const groupedProducts =
    basketStatesFromLocalStorage?.products.length &&
      basketStatesFromLocalStorage?.products[0].product
      ? Object.values(
        basketStatesFromLocalStorage?.products.length &&
        basketStatesFromLocalStorage?.products[0].product &&
        basketStatesFromLocalStorage?.products.reduce((acc, item) => {
          const key = item.product.creator; // Use creator as the unique identifier

          if (!acc[key]) {
            acc[key] = { ...item }; // Store the first instance of the product
          } else {
            // Sum up quantities and totals for duplicate products
            acc[key].quantity += item.quantity;
            acc[key].total += item.total;
          }

          return acc;
        }, {}),
      )
      : [];
  const ShowCurrencies2 = (currency, _price) => {
    let tempCurrency = currency && currency.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString("en", options);
    }
    let _final = "";
    price = price ? price.toLocaleString() : 0;
    switch (tempCurrency) {
      case "GBP":
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case "EUR":
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case "MYR":
        _final = `${price}`;
        break;
      case "IDR":
        _final = `Rp${price}`;
        break;
      case "AED":
        _final = `Dh${price}`;
        break;
      default:
        _final = `$${price}`;
        break;
    }
    return _final;
  };

  const getAddOnProducts = async () => {
    try {
      const response = await services.getSideAddonProducts();
      function uniqBy(a) {
        var seen = {};
        return a.filter(function (item) {
          var k = item.creator;
          return seen.hasOwnProperty(k) ? false : (seen[k] = true);
        })
      }
      response.data = response.data.filter(item => Number(item.status) === 1 && ["PAO-EMR-2025-001", "PAO-RM25-2025-02", "PAO-RM25-2025-03", "PAO-EMR-2025-003", "PAO-EMR-2025-004", "PAO-EMR-2025-005"].includes(item.creator))
      let tempNewProducts = uniqBy(response.data)
      let tempGazaMedicalProduct = [];
      let tempFoodProducts = [];

      allProducts.forEach((item) => {
        item.sub_categories.forEach((inner_item) => {
          inner_item.products.forEach((inner_item_product) => {
            if (Number(inner_item_product.status) === 1) {
              if(inner_item_product.creator === 'PAO-EMR-2025-002') tempGazaMedicalProduct.push(inner_item_product);
              if(inner_item_product.creator === 'PAO-EMR-2025-003') tempFoodProducts.push(inner_item_product);
              if(inner_item_product.creator === 'PAO-EMR-2025-004') tempFoodProducts.push(inner_item_product);
              if(inner_item_product.creator === 'PAO-EMR-2025-005') tempFoodProducts.push(inner_item_product);
            }
            // if (Number(inner_item_product.status) === 1 && inner_item_product.creator === 'PAO-EMR-2025-002') {
            //   tempGazaMedicalProduct.push(inner_item_product);
            // }
          });
        });
      });

      const mergedAddOnProducts = tempNewProducts.concat(tempGazaMedicalProduct,tempFoodProducts);
      sortAddOnProducts(mergedAddOnProducts, selectedCurrencyStore)

    } catch (error) {
      console.error('error========', error);
    }
  };
  const sortAddOnProducts = (products, currency) => {
    let testCompare = products;//products.sort((a, b) => b[currency.toLowerCase()] - a[currency.toLowerCase()])
    // console.log("sorted", selectedCurrencyStore, currency, testCompare);

    const index = products.findIndex(item => item.creator === "PAO-EMR-2025-002");
    if (index !== -1) {
      const rddProduct = products[index];
      products.splice(index, 1); // remove from current position
      products.splice(1, 0, rddProduct); // insert at index 1
    }

    setUserProductsCount(userProductsCount + 1);
    setTopProducts(testCompare);
  }

  useEffect(() => {
    if(allProducts && Object.keys(allProducts).length) {
      topProducts[0][selectedCurrencyStore.toLowerCase()] && sortAddOnProducts(topProducts, selectedCurrencyStore);
      selectedCurrencyStore && getAddOnProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore,allProducts])

  return (
    <>
      {/* {showClose && (
        <div
          className={`z-[9999] pointer-events-none h-7 w-7 fixed -translate-x-1/2 -translate-y-1/2 text-white ${
            position.y <= 12 ? 'opacity-0' : 'opacity-100'
          }`}
          style={{
            top: position.y,
            left: position.x,
          }}
        >
          <TfiClose className=" h-7 w-7 font-thin opacity-70" />
        </div>
      )} */}

      <Sheet
      // refTo={hideAreaRef}
      >
        <div className={`h-full flex flex-col`}>
          <div
            className={`px-4 pt-3 md:pt-6 bg-[#0286c3] ${isUsa ? "mb-4 md:mb-6" : ""}`}
          >
            <div className="flex justify-between gap-6 items-center">
              <div className="text-white cursor-pointer">
                <CustomButton
                  onClick={() => dispatch(closeSheet())}
                  title="add more items"
                  className="!text-sm !h-[32px] !font-signika !rounded-none"
                />
              </div>
              <div className="text-white font-light cursor-pointer h-[24px] w-[24px] rounded-full flex justify-center items-center">
                <FaTimes
                  onClick={() => dispatch(closeSheet())}
                  className="font-extrabold text-2xl"
                  aria-hidden="true"
                />
              </div>
            </div>
            {/* {!isUSARoute && (
              <div className="border-b border-[#00afff80] py-6 mb-6 md:mb-8">
                <p className="font-signika uppercase text-xl font-bold tracking-[2px] text-white md:max-w-[221px]">
                  My Donations
                </p>
              </div>
            )} */}
          </div>
          <div className="px-4 md:px-10 bg-[#0286c3] mt-4">
            {/* {console.log("html=", basketStatesFromLocalStorage, groupedByCreator)} */}
            {/* {basketStatesFromLocalStorage && basketStatesFromLocalStorage.products.length && basketStatesFromLocalStorage.products[0].product
              ? basketStatesFromLocalStorage.products.map((item, index) => {
                return <div key={`items${index}`}>
                  <ProductBoxCartSheet
                    product={item}
                    onAddQty={() => onAddQty(item.product)}
                    onReduceQty={() => onReduceQty(item.product)}
                    onUpdateQty={() => onUpdateQty(item.product)}
                    onRemove={() => onRemove(item.product)}
                  />
                </div>
              })
              : <p className="font-signika text-xl text-white font-semibold text-center">No Items found</p>
            } */}
            {basketStatesFromLocalStorage?.products.length &&
              basketStatesFromLocalStorage?.products[0].product &&
              groupedProducts.length > 0 ? (
              groupedProducts.map((item, index) => (
                <div key={`items${index}`}>
                  <ProductBoxCartSheet
                    product={item}
                    onAddQty={() => onAddQty(item.product)}
                    onReduceQty={() => onReduceQty(item.product)}
                    // onUpdateQty={() => onUpdateQty(item.product, 444)}
                    onRemove={() => onRemove(item.product)}
                    item={item.product}
                  />
                </div>
              ))
            ) : (
              <p className="font-signika text-xl text-white font-semibold text-center">
                No Items found
              </p>
            )}
            {/* {Object.values(groupedByCreator).length > 0 && total > 0 ? (
              Object.values(groupedByCreator).map((item, index) => (
                // <div key={index} className="product-card p-3 bg-green-500 m-3">
                //     <h3>{item?.creator}</h3>
                //     <p>Total: {item?.total}</p>
                //     <button onClick={() => removeProduct(item)}>Remove</button>
                // </div>
                <ProductBoxCartSheet
                  product={item}
                  onAddQty={onAddQty}
                  onReduceQty={onReduceQty}
                  onUpdateQty={onUpdateQty}
                  onRemove={onRemove}
                />
              ))
            ) : (
              <p className="font-signika text-xl text-white font-semibold text-center">No Items found</p>
            )} */}
            {/* {products.length > 0 ? (
              <ProductBoxCartSheet
                selectedProducts={selectedProducts}
                onAddQty={onAddQty}
                onReduceQty={onReduceQty}
                onUpdateQty={onUpdateQty}
                onRemove={onRemove}
              />
            ) : (
              <p className="font-signika text-xl text-white font-semibold text-center">No Items found</p>
            )} */}
          </div>
          <div className="px-4 md:px-10 bg-[#0286c3] pb-4 md:pb-10 mt-auto">
            <div className="flex flex-col gap-2 md:gap-4 mt-auto">
              {basketStatesFromLocalStorage &&
                basketStatesFromLocalStorage.grandTotal > 0 && (
                  <div className={`mt-6 md:mt-8 border-b border-[#00afff80] pb-4 md:pb-6 ${!isUsa ? 'mb-2 md:mb-4' : ''}`}>
                    <div className="flex flex-col gap-3">
                      {isUsa && (
                        <div className="border-b border-[#00afff80] pb-2 md:pb-4">
                          <AdminCosts
                            basketStates={JSON.parse(localStorage.getItem(BASKET_CACHE_KEY))}
                            setBasketStates={setBasketStates}
                            selectedSubscribe={selectedSubscribe}
                            currency={
                              selectedCurrencyStore
                                ? selectedCurrencyStore
                                : basketStates?.currency
                            }
                            onChange={(e) => handleAdminCost(e)}
                            isSheet={true}
                          />
                        </div>
                      )}
                      <div className="flex flex-col items-center gap-2 border-b border-[#00afff80] pb-2 md: pb-4">
                        <h2 className="font-signika text-xl md:text-2xl font-bold text-white mb-2">
                        Want to make a bigger impact?
                        </h2>
                        {topProducts.map((product, index) => {
                          return <ProductBoxCart
                            key={`product-${index}`}
                            product={product}
                            onCheck={handleCheckProduct}
                            onAdd={handleAddToGiving}
                            onRemove={handleRemoveProduct}
                            currency={selectedCurrencyStore}
                            stateVariableObj={{ productName: product.name }}
                            _lang={'eng'}
                            linkBtnClasses={product.linkBtnClasses || ''}
                            isStaticProduct={['Zakat Calculator'].includes(product.product_type)}
                            hideCount
                            staticLink={
                              product.product_type
                                ? product.short_description
                                : ''
                            }
                            staticLinkText={
                              product.product_type ? product.name : ''
                            }
                          />
                        })}
                      </div>
                      <div className="flex items-center gap-4 justify-between">
                        <p className="font-signika text-3xl md:text-4xl font-bold text-white">
                          Total
                        </p>
                        <p className="font-signika text-2xl md:text-4xl font-bold text-white self-end">
                          {selectedCurrencyStore === "MYR" ||
                            selectedCurrencyStore === "SGD" ? (
                            <span className="text-sm ml-0.5">
                              {selectedCurrencyStore}
                            </span>
                          ) : (
                            ""
                          )}{" "}
                          {/* {ShowCurrencies2(
                            selectedCurrencyStore,
                            basketStatesFromLocalStorage.grandTotal
                          )} */}
                          {ShowCurrencies2(
                            selectedCurrencyStore,
                            basketStatesFromLocalStorage.grandTotal -
                              (!isUsa
                                ? basketStatesFromLocalStorage?.adminCost
                                : 0),
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              {isUsa && (
                <>
                  {basketStatesFromLocalStorage?.grandTotal > 0 && (
                    <Elements stripe={stripePromise}>
                      <OneClickCheckout />
                    </Elements>
                  )}
                </>
              )}
              <div className="bg-[#0286c3] flex gap-3 group">
                <CustomButton
                  onClick={() => dispatch(closeSheet())}
                  title="Back"
                  icoLeft={<IoReturnUpBackOutline className="mr-2" />}
                  className="!h-[52px] md:!h-[56px] !font-signika uppercase !w-full font-bold checkout-btn-bg !text-[20px] md:!text-2xl flex-1 !bg-[#00a4da3f] hover:!bg-[#00a4da6e] !rounded-none"
                />
                {basketStatesFromLocalStorage &&
                  basketStatesFromLocalStorage.grandTotal > 0 && (
                    <CustomButton
                      onClick={() => {
                        localStorage.setItem(
                          "IS_DAILY_DONATION",
                          JSON.stringify(false),
                        );
                        navigate(generateLinkWithLang("/checkout", lang));
                        dispatch(closeSheet());
                      }}
                      title="Checkout"
                      className="!h-[52px] md:!h-[56px] !font-signika uppercase !w-full font-bold checkout-btn-bg !text-[20px] md:!text-2xl flex-grow !rounded-none"
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </Sheet>
    </>
  );
};